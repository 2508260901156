<template>
  <div class="vx-row">
    <div class="vx-col sm:w-full md:w-1/3">
      <vx-card title="Information">
        <table class="info">
          <tr>
            <td class="font-semibold">Transaction Date</td>
            <td>{{data.date_transaction|moment("dddd, Do MMMM YYYY")}}</td>
          </tr>
          <tr>
            <td class="font-semibold">Customer Bill</td>
            <td>{{data.customer}}</td>
          </tr>
          <tr>
            <td class="font-semibold"></td>
            <td>{{data.address}}</td>
          </tr>
          <tr>
            <td class="font-semibold">Total Payment</td>
            <td class="font-semibold">{{data.total_payment|numFormat}}</td>
          </tr>
          <tr>
            <td class="font-semibold">Description</td>
            <td>{{data.description}}</td>
          </tr>
          <tr>
            <td class="font-semibold">Time Input</td>
            <td>{{data.created_at|moment("Do MMM YYYY @ HH:mm")}}</td>
          </tr>
        </table>
        <div class="flex">
          <vs-button type="border" :to="{name:'bills'}" color="warning">Back</vs-button>
        </div>
      </vx-card>
    </div>
    <div class="vx-col sm:w-full md:w-2/3">
      <vx-card title="Detail">
        <vs-table :data="details">
          <template slot="thead">
            <vs-th>Invoice No.</vs-th>
            <vs-th>Receivable Date</vs-th>
            <vs-th>Invoice Due</vs-th>
            <vs-th>Total Bill</vs-th>
            <vs-th>Total Payment</vs-th>
            <vs-th>Description</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr v-for="(tr, i) in data" :key="i">
              <vs-td>
                <router-link :to="{name: 'invoices-show', params:{id: tr.invoice_id}}">{{tr.invoice_code}}</router-link>
              </vs-td>
              <vs-td label="Receivable Date">{{tr.receivable_date|moment("Do MMM YYYY")}}</vs-td>
              <vs-td label="Invoice Due">{{tr.due_date|moment("Do MMM YYYY")}}</vs-td>
              <vs-td label="Total Bill" class="text-right">{{tr.total_bill|numFormat}}</vs-td>
              <vs-td label="Total Payment" class="text-right font-semibold">{{tr.total|numFormat}}</vs-td>
              <vs-td label="Description">{{tr.description}}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vx-card>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  data(){
    return {
      data: {},
      details: []
    }
  },
  methods:{
    ...mapActions({
      dispatchShow: 'bills/show',
      dispatchDetails: 'bills/details'
    }),
    async getData(){
      let item = await this.dispatchShow(this.$route.params.id)
      this.data = item.data
      let details = await this.dispatchDetails(this.$route.params.id)
      this.details = details.data
    }
  },
  mounted(){
    this.getData()
  }
}
</script>

<style>

</style>